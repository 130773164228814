import React, {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import RegistrationForm from "../../../components/RegistrationForm";
import "./style.css";
import {getSubscriptionStatus} from "../../../services/course.service";
import {CourseModel, SubscriptionModel, UserModel} from "../../../@types/model";
import {formatPrice} from "../../../constants/course";
import {useSelector} from "react-redux";
import getUser from "../../../reducers/selectors/getUser";
import LoginForm from "../../../components/LoginForm";
import PaymentForm from "../../../components/PaymentForm";
import {SubscriptionStatus} from "../../../constants/subscription";

const CourseSubscription = () => {

    const user: undefined | UserModel = useSelector(getUser);

    const [isLoading, setIsLoading] = useState(true);
    const [course, setCourse] = useState<CourseModel>();
    const [subscription, setSubscription] = useState<SubscriptionModel>();
    const [showMore, setShowMore] = useState(true);

    const {id} = useParams<{ id: string }>();

    useEffect(() => {
        setIsLoading(true);
        getSubscriptionStatus(parseInt(id!)).then((response: any) => {
            setCourse(response.data.data.course);
            setSubscription(response.data.data.subscription);
            setIsLoading(false);
        });
    }, [id, user]);

    return (
        <Fragment>
            <div className='logo-content'>
                <div className="app-logo"/>
            </div>
            <div className="container py-3">
                <div className="row">
                    {isLoading ? (
                        <div className="loading-container">Loading...</div>
                    ) : (
                        <>
                            <div className="col-md-6" style={{display: 'flex', flexDirection: 'column'}}>
                                <div style={{height: '700px'}}>
                                    <img
                                        className="thumbnail-image"
                                        alt={"Logo " + course?.name}
                                        src={course?.thumbnail ?? ''}
                                    ></img>
                                </div>
                                <div className="abstract-container">
                                    <h3 className="abstract-title">{course?.name}</h3>
                                    <h5 className="abstract-text">{course?.abstract}</h5>
                                </div>

                                <div className="row">
                                    <div
                                        style={{height: showMore ? '250px' : 'auto', overflow: 'hidden'}}
                                        dangerouslySetInnerHTML={{__html: course?.description ?? ''}}
                                    />
                                    <button
                                        className="btn btn-success"
                                        style={{maxWidth: '100px', margin: '0 auto'}}
                                        onClick={() => setShowMore(!showMore)}
                                    >
                                        Leggi {showMore ? 'tutto' : 'meno'}
                                    </button>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="data-row">
                                    <div className="bold-text">Luogo: &nbsp;</div>
                                    <div className="text">{course?.place}</div>
                                </div>

                                <div className="data-row">
                                    <div className="bold-text">Selde: &nbsp;</div>
                                    <div className="text">{course?.location}</div>
                                </div>

                                <div className="data-row">
                                    <div className="bold-text">Prezzo: &nbsp;</div>
                                    <div className="text">{formatPrice(parseFloat(course?.price || "0"))}</div>
                                </div>

                                <div className="data-row">
                                    <div className="bold-text">Posti: &nbsp;</div>
                                    <div className="text">{course?.seats}</div>
                                </div>

                                <div className="data-row">
                                    <div className="bold-text">Ore: &nbsp;</div>
                                    <div className="text">{course?.hours}</div>
                                </div>

                                <div className="data-row">
                                    <div className="bold-text">da: &nbsp;</div>
                                    <div className="text">{course?.date_start}</div>
                                </div>

                                <div className="data-row">
                                    <div className="bold-text">al: &nbsp;</div>
                                    <div className="text">{course?.date_end}</div>
                                </div>

                                <div className="data-row">
                                    <div className="bold-text">Crediti Sanitari: &nbsp;</div>
                                    <div className="text">{course?.credits_san}</div>
                                </div>

                                <div className="data-row">
                                    <div className="bold-text">Crediti Sociali: &nbsp;</div>
                                    <div className="text">{course?.credits_soc}</div>
                                </div>

                                <div>
                                    <h3 className='text-center mt-4'>Hai già partecipato ad un corso?</h3>
                                    {!user && <p className='text-center'>
                                        Se hai già partecipato ad un corso accedi per riutilizzare i tuoi dati<br/>
                                    </p>}
                                    <LoginForm/>
                                </div>

                                <h3 className='text-center mt-4'>{user ? `Bentornato ${user.name}` : "Sei nuovo?"}</h3>
                                {!subscription && <Fragment>
                                    <p className='text-center'>
                                        Compila il seguente form per completare la prenotazione<br/>
                                    </p>
                                    <RegistrationForm
                                        id={parseInt(id!)}
                                        isPrivate={course?.protected ?? false}
                                        user={user}
                                        setSubscription={setSubscription}
                                    />
                                </Fragment>}
                                {subscription?.status === SubscriptionStatus.PENDING &&
                                    <PaymentForm
                                        id={parseInt(id!)}
                                        subscription={subscription}
                                        course={course!}
                                        onSuccess={setSubscription}
                                    />}
                                {subscription?.status === SubscriptionStatus.PAYED &&
                                    <div className="alert alert-warning">
                                        Un nostro operatore sta verificando la tua richiesta di registrazione.
                                        Riceveà una e-mail ad avvenuta conferma.
                                    </div>}
                                {subscription?.status === SubscriptionStatus.CONFIRMED &&
                                    <div className="alert alert-success">
                                        Complimenti sei iscritto correttamente per quest'evento.
                                    </div>}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Fragment>
    );
};

export default CourseSubscription;
