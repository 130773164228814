import React, {useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {uploadSubscriptionReceipt} from '../../services/course.service';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCloudArrowUp, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {CourseModel, SubscriptionModel} from "../../@types/model";
import PayPalButton from "./paypal";

interface PaymentFormInterface {
    id: number;
    subscription: SubscriptionModel
    course: CourseModel
    onSuccess: (s: SubscriptionModel) => void;
}

function PaymentForm(props: PaymentFormInterface) {

    const {id, course, subscription, onSuccess} = props;

    const [loading, setLoading] = useState(false)

    const onImageSelected = (thumbnailImageEvent) => {
        setLoading(true)
        const fileRef: any = thumbnailImageEvent.target.files[0];
        if (fileRef) {
            let formData = new FormData();
            formData.append('file', fileRef);
            uploadSubscriptionReceipt(id, formData)
                .then((response) => {
                    onSuccess(response.data.data)
                    setLoading(false)
                })
                .catch(() => {
                    setLoading(false)
                })
        }
    }

    return <div className='well'>
        <div className='alert alert-success text-center'>
            Complimenti la registrazione è andata a buon fine
        </div>
        <div className='mt-4'>
            <h3 className='text-center'>Paga con paypal</h3>
            {parseFloat(course.price) === 12 || parseFloat(course.price) === 80 ?
                <form action='https://www.paypal.com/cgi-bin/webscr' method='post' target='_top' className='text-center'>
                    <input type='hidden' name='cmd' value='_s-xclick'/>

                    {parseFloat(course.price) === 12 && <input type='hidden' name='hosted_button_id' value='PCUVEG2UHYAG2'/>}
                    {parseFloat(course.price) === 80 && <input type='hidden' name='hosted_button_id' value='9DYCZC9MWSMEG'/>}

                    <input type='hidden' name='item_number' value={id}/>
                    <input type='hidden' name='notify_url'
                           value={`https://api.professionisanitarie.com/v1/course/${id}/confirm-payment?user=${id}`}/>
                    <input type="hidden" name="return" value={`${process.env.REACT_APP_APP_URL}/iscrizione/corso/${id}`}/>

                    <input
                        type='image'
                        src='https://www.paypalobjects.com/it_IT/IT/i/btn/btn_buynowCC_LG.gif'
                        name='submit'
                        alt='PayPal è il metodo rapido e sicuro per pagare e farsi pagare online.'/>
                    <img
                        alt=''
                        src='https://www.paypalobjects.com/it_IT/i/scr/pixel.gif'
                        width='1' height='1'/>
                </form>
                :
                <PayPalButton
                    subscription={subscription}
                    onSuccess={onSuccess}
                />
            }
        </div>
        <div className='receipt-form text-center mt-4'>
            <h3>Pagamento tramite bonifico</h3>
            <div className='mt-2'>
                Modalità di pagamento: addebito su c/c 98209117<br/>
                Intestato a APSI Associazione Professioni Sanitarie Italiana<br/>
                Acceso presso Poste Italiane<br/>
                IBAN: IT91B0760116000000098209117<br/>
            </div>
            <div className='my-4'>
                <small className='alert alert-warning'>
                    Allega la copia del pagamento per poter partecipare all'evento<br/>
                </small>
            </div>
            <div className='my-2'>
                <label className='btn btn-primary' htmlFor='receipt'>
                    {loading ?
                        <FontAwesomeIcon icon={faSpinner} size='lg' className='mx-2 fa-spin'/>
                        :
                        <FontAwesomeIcon icon={faCloudArrowUp} size='lg' className='mx-2'/>
                    }
                    Seleziona il file
                </label>
                <input
                    type='file'
                    id='receipt'
                    className='input'
                    hidden
                    onChange={onImageSelected}
                />
            </div>
        </div>
    </div>
}

export default PaymentForm;
